<div class="padding">
    <form [formGroup]="lienDetails">
        <app-term [uiConfiguration]="uiConfiguration.lienDetailsConfiguration!" [errors$]="observableErrors$" [order]="order" [isDisabled]="isDisabled"></app-term>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showAmount" class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Amount</mat-label>
                    <input matInput data-testid="amount-field" placeholder="Amount" formControlName="amount" type="number">
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.controls['amount']"
                    [message]="amountError$"></lib-error-bubble>
            </div>
        </div>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showTrustIndenture ||
                    uiConfiguration.lienDetailsConfiguration?.showStillHavePossession" class="flex-container flex-item padding-top">
            <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showTrustIndenture" class="validated-form-field-container flex-container">
                <mat-checkbox class="flex-item" formControlName="trustIndenture" [disabled]="isDisabled"
                    data-testid="trust-indenture-checkbox">
                    Trust Indenture
                </mat-checkbox>
                <lib-error-bubble [control]="lienDetails.controls['trustIndenture']"
                    [message]="trustIndentureError$"></lib-error-bubble>
            </div>
            <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showStillHavePossession" class="validated-form-field-container flex-container">
                <mat-checkbox class="flex-item" formControlName="stillHavePossession" [disabled]="isDisabled"
                    data-testid="possession-checkbox">
                    Still Have Possession
                </mat-checkbox>
            </div>
        </div>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showVehicleReleasedDate" class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="form-field flex-item" appearance="outline">
                    <mat-label>Vehicle Released Date</mat-label>
                    <input matInput [matDatepicker]="vehicleReleasedDate" formControlName="vehicleReleasedDate"
                        data-testid="vehicle-released-date-field" placeholder="YYYY-MM-DD">
                    <mat-datepicker-toggle matSuffix [for]="vehicleReleasedDate"></mat-datepicker-toggle>
                    <mat-datepicker #vehicleReleasedDate></mat-datepicker>
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.get('vehicleReleasedDate')!" [message]="vehicleReleasedDateError$"></lib-error-bubble>
            </div>
        </div>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showWorkLastCompletedDate" class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="form-field flex-item" appearance="outline">
                    <mat-label>Work Last Completed Date</mat-label>
                    <input matInput [matDatepicker]="workLastCompletedDate" formControlName="workLastCompletedDate"
                        data-testid="work-last-completed-date-field" placeholder="YYYY-MM-DD">
                    <mat-datepicker-toggle matSuffix [for]="workLastCompletedDate"></mat-datepicker-toggle>
                    <mat-datepicker #workLastCompletedDate></mat-datepicker>
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.get('workLastCompletedDate')!" [message]="workLastCompletedDateError$"></lib-error-bubble>
            </div>
        </div>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showProvidedPartsOrAccessories" class="validated-form-field-container flex-container">
            <mat-checkbox class="flex-item" formControlName="providedPartsOrAccessories" [disabled]="isDisabled"
                data-testid="provided-parts-or-accessories-checkbox">
                Provided Parts or Accessories
            </mat-checkbox>
        </div>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showPartsProvidedDate" class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="form-field flex-item" appearance="outline">
                    <mat-label>Parts Provided Date</mat-label>
                    <input matInput [matDatepicker]="partsProvidedDate" formControlName="partsProvidedDate"
                        data-testid="parts-provided-date-field" placeholder="YYYY-MM-DD">
                    <mat-datepicker-toggle matSuffix [for]="partsProvidedDate"></mat-datepicker-toggle>
                    <mat-datepicker #partsProvidedDate></mat-datepicker>
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.get('partsProvidedDate')!" [message]="partsProvidedDateError$"></lib-error-bubble>
            </div>
        </div>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showAdditionalInformation" class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Additional Information</mat-label>
                    <textarea matInput data-testid="additional-information-field" formControlName="additionalInformation"
                        placeholder="Additional information"></textarea>
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.controls['additionalInformation']"
                    [message]="additionalInformationError$"></lib-error-bubble>
            </div>
        </div>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showCourtOrder" class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Court Order</mat-label>
                    <textarea matInput data-testid="court-order-field" formControlName="courtOrder"
                        placeholder="Court Order"></textarea>
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.controls['courtOrder']"
                    [message]="courtOrderError$"></lib-error-bubble>
            </div>
        </div>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showOtherChanges" class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Other Changes</mat-label>
                    <textarea matInput data-testid="other-changes-field" formControlName="otherChanges"
                        placeholder="Other Changes"></textarea>
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.controls['otherChanges']"
                    [message]="otherChangesError$"></lib-error-bubble>
            </div>
        </div>
    </form>
</div>