import { Component, OnDestroy, OnInit } from '@angular/core';
import { Order, OrderManagerService, OrderMessagesComponent, OrderRepositoryService, OrderStateRepositoryService, eOrderType, eOrderTypeGroup } from 'reg-hub-common';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmSubmitComponent } from '../confirm-submit/confirm-submit.component';
import { Subject, Subscription, takeUntil, timer } from 'rxjs';
import { ConfirmDeleteComponent } from '../confirm-delete/confirm-delete.component';
import { ConfirmCancelComponent } from '../confirm-cancel/confirm-cancel.component';
import { NavigationHistoryService } from '../../services/navigation-history-service/navigation-history.service';
import { OrdersHubService } from '../../services/hubs/orders-hub/orders-hub.service';

@Component({
  selector: 'app-new-business-search',
  templateUrl: './new-business-search.component.html',
  styleUrls: ['./new-business-search.component.css']
})
export class NewBusinessSearchComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  private orderStateSubscription?: Subscription;

  orderId: string = "";
  order: Order = {
    referenceNumber: "",
    country: "CA",
    jurisdiction: "",
    orderTypeID: eOrderType.notSet,
  } as Order;

  orderTypeGroupID: eOrderTypeGroup = eOrderTypeGroup.BusinessSearch;
  isLoadingOrder: boolean = false;
  finalStepIsSelected: boolean = false;

  constructor(private activatedRoute: ActivatedRoute,
    private orderRepo: OrderRepositoryService,
    private orderManager: OrderManagerService,
    private orderStateRepo: OrderStateRepositoryService,
    private dialog: MatDialog,
    private router: Router,
    private navigationService: NavigationHistoryService,
    private ordersHub: OrdersHubService,
    private route: ActivatedRoute
  ){ }

  ngOnInit(): void {
    this.orderManager.currentOrder
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(order => this.order = order);

    this.activatedRoute.paramMap.subscribe(params => {
      this.orderId = params.get('orderID')!;
      if (this.orderId) {
        // If we have an orderID, load the associated order and stepper
        this.getOrder(this.orderId);
      } else {
        this.resetOrder();
        // if we don't have an orderID, load base stepper
        this.orderManager.updateOrder(this.order);
      }
    });
  }

  private getOrder = (orderID: string) => {
    this.isLoadingOrder = true;
    this.orderRepo.getOrder('orders', orderID)
      .subscribe(order => {
        this.isLoadingOrder = false;
        this.order = order as Order;

        this.orderManager.updateOrder(this.order);
      })
  }

  messageCount() {
    let length = this.order?.orderMessages?.filter((u) => u.isCustomerVisible && !u.isReadByCustomer).length ?? 0;
    return length > 0 ? length.toString() : "";
  }

  shouldShowOrderMessages(): boolean {
    return this.order.orderStatusTypeID == 'Invalid'
      || this.messageCount() != "";
  }

  openOrderMessages() {
    this.dialog.open(OrderMessagesComponent, {
      data: {order: this.order, customer: true },
      minWidth: '800px'
    });
  }

  submissionConfirmation() {
    const dialogRef = this.dialog.open(ConfirmSubmitComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // submission is confirmed, submit order
        this.submitOrder();
      }
    });
  }

  submitOrder() {
    //Limit flashing of order spinner
    var timerSubscription = timer(250).subscribe(() => this.isLoadingOrder = true);

    const pendingStatus = {
      newStatus: "Pending",
      status: "",
      message: "Submitted from Reg Hub Client"
    }

    this.orderStateSubscription = this.orderStateRepo.updateOrderState(this.order.id, pendingStatus)
      .subscribe({
        next: (result) => {
          timerSubscription.unsubscribe();
          this.isLoadingOrder = false;

          if (!result) {
            this.ordersHub.joinOrderGroup(this.order);
            this.order.orderStatusTypeID = "Pending";
            this.orderManager.updateOrder(this.order);
            
            const originatingOrderIDParam = this.route.snapshot.queryParamMap.get('originatingOrderID'); 
            if (this.order.orderTypeID == eOrderType.businessReport
              && originatingOrderIDParam != null) {
                this.router.navigate(['orders', originatingOrderIDParam], { 
                  queryParams: {} 
                });
            } else {
              this.router.navigate(['orders', this.order.id], { 
                queryParams: {} 
              });
            }
          }
        },
        error: () => {
          timerSubscription.unsubscribe();
          this.isLoadingOrder = false;
        }
      });
  }

  deletionConfirmation() {
    if (this.order?.orderStatusTypeID == "Draft" ||
      this.order?.orderStatusTypeID == "HistoricalDraft") {
      const dialogRef =this.dialog.open(ConfirmDeleteComponent);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // deletion is confirmed, delet order
          this.deleteOrder();
        }
      });
    }
  }

  deleteOrder() {
    const orderID = this.order.id;

    this.orderRepo.deleteOrder("Orders/" + this.order.id)
    .subscribe({
      next: () => {
        let previousUrl = this.navigationService.getOrderDeletionRedirectUrl(orderID);
        this.router.navigateByUrl(previousUrl);
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  cancelConfirmation() {
    const dialogRef =this.dialog.open(ConfirmCancelComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // deletion is confirmed, delet order
        this.cancelOrder();
      }
    });
  }

  cancelOrder() {
    var timerSubscription = timer(250).subscribe(() => this.isLoadingOrder = true);

    const pendingStatus = {
      newStatus: "Cancelled",
      status: "",
      message: "Cancelled from Reg Hub Client"
    }

    this.orderStateRepo.updateOrderState(this.order.id, pendingStatus)
      .subscribe({
        next: (result) => {
          timerSubscription.unsubscribe();
          this.isLoadingOrder = false;

          if (!result) {
            this.order.orderStatusTypeID = "Cancelled";
            this.orderManager.updateOrder(this.order);
            this.router.navigate(['orders', this.order.id]);
          }
        },
        error: () => {
          timerSubscription.unsubscribe();
          this.isLoadingOrder = false;
        }
      });
  }

  ngOnDestroy(): void {
    // Signal all subscriptions to complete
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    // Unsubscribe to prevent memory leaks
    if (this.orderStateSubscription) {
      this.orderStateSubscription.unsubscribe();
    }
  }

  private resetOrder() {
    this.order = {
      referenceNumber: "",
      country: "CA",
      jurisdiction: "",
      orderTypeID: eOrderType.notSet,
    } as Order;
  }
}
