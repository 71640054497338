<div class="flex-container">
    <mat-list class="flex-item">
        <mat-list-item *ngIf="!isEditingFileNumber">
            <div class="list-item-content">
                <span class="label" data-testid="base-order-reference-number-text">Reference Number: </span>
                <span class="flex-item-fixed-size-no-padding">{{ order.referenceNumber }}</span>
                <button *ngIf="orderIsInDraft()" class="padding-sides-15" mat-icon-button color="primary" (click)="editReferenceNumber()" aria-label="View Order"
                    data-testid="base-order-edit-reference-button">
                    <mat-icon>edit</mat-icon>
                </button>
            </div>
        </mat-list-item>
        <mat-list-item *ngIf="isEditingFileNumber" class="overflow">
            <div class="list-item-content translate-down-5">
                <mat-form-field class="form-field" appearance="outline">
                    <mat-label>Reference Number</mat-label>
                    <input matInput [(ngModel)]="order.referenceNumber" (keyup.enter)="saveReferenceNumber()" placeholder="Reference Number" 
                        data-testid="base-order-reference-number-field">
                </mat-form-field>
                <button class="padding-sides-15 translate-up-10" mat-icon-button color="primary" (click)="saveReferenceNumber()" aria-label="View Order"
                    data-testid="base-order-save-reference-button">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
        </mat-list-item>
        <mat-list-item>
            <div class="list-item-content">
                <span class="label">Order Type:</span>
                <span class="value" data-testid="base-order-type-text">{{ order.orderType.name }}</span>
            </div>
        </mat-list-item>
        <mat-list-item>
            <div class="list-item-content">
                <span class="label">Order Status:</span>
                <span class="value" data-testid="base-order-status-text">{{ order.orderStatusTypeID }}</span>
            </div>
        </mat-list-item>
    </mat-list>

    <mat-list class="flex-item">
        <mat-list-item>
            <div class="list-item-content">
                <span class="label">Requested By:</span>
                <span class="value" data-testid="base-order-requested-by-text">{{ requestingUser }}</span>
            </div>
        </mat-list-item>
        <mat-list-item>
            <div class="list-item-content">
                <span class="label">Country:</span>
                <span class="value" data-testid="base-order-country-text">{{ order.country }}</span>
            </div>
        </mat-list-item>
        <mat-list-item>
            <div class="list-item-content">
                <span class="label">Jurisdiction:</span>
                <span class="value" data-testid="base-order-jurisdiction-text">{{ order.displayJurisdiction }}</span>
            </div>
        </mat-list-item>
        <mat-list-item *ngIf="showQcForm(order)">
            <div class="list-item-content">
                <span class="label">Form Type:</span>
                <span class="value" data-testid="base-order-qc-form-text">{{ order.lien!.qcFormTypeID! }}</span>
            </div>
        </mat-list-item>
    </mat-list>
</div>