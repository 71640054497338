import { StepValidationState } from "projects/reg-hub-client/src/interfaces/step";
import { SteppedComponent } from "projects/reg-hub-client/src/interfaces/stepped-component";
import { eGeneration, ePartyType, Order, OrderManagerService, OrderUIConfiguration, Party, PartyForCreation, PartyRepositoryService, UIConfigurationService, ValidationProblem, ValidationService } from "reg-hub-common";
import { BehaviorSubject, filter, map, Observable, tap } from "rxjs";

export abstract class PartiesComponent extends SteppedComponent {
    parties$ = new BehaviorSubject<Party[]>([]);

    protected isEditable: boolean = true;

    protected newParty: Party | null = null;

    constructor(
        private partyRepo: PartyRepositoryService,
        private uiConfigurationService: UIConfigurationService,
        orderManager: OrderManagerService,
        validationService: ValidationService) {
        super(orderManager, validationService);
    }

    protected abstract getParties(): Party[];

    protected override init(order: Order): void {
        super.init(order);

        this.parties$.next(this.getParties());
    }

    public override onSaving(): void {
        
    }

    public override pushErrors(errors: ValidationProblem[] | undefined): void {

    }

    getUIConfiguration() {
        this.uiConfigurationService.getOrderUIConfiguration(this.order)
            .subscribe(config => this.uiConfiguration = config);
    }

    handlePartyAddedEvent(addedParty: Party) {
        this.createParty(addedParty).subscribe(party => {
            this.newParty = party;
        });
    }

    handlePartySavedEvent(savedParty: Party) {
        if (!savedParty.id) {
            // if we don't have an ID yet, create the Party
            this.createParty(savedParty).subscribe();
        } else {
            // otherwise, the Party already exists, so we should update it
            this.updateParty(savedParty);
        }
    }

    handleExistingPartySaveEvent(existingParty: PartyForCreation) {
        this.partyRepo.createNewParty(`orders/${this.order.id}/parties`, existingParty)
            .subscribe(result => {
                const party = ((result as any).resource) as Party;
                this.order.parties = this.order.parties!.concat([party]);
                this.orderManager.updateOrder(this.order);
                this.parties$.next(this.getParties());
            });
    }

    handlePartyCopiedEvent(copiedParty: Party) {
        // create a full copy of the party so we don't overwrite the party from which we copied
        const copiedPartyWithoutId = { ...copiedParty };
        
        // ||= sets the value if and only if the left side is undefined, null, 0, "" or another falsey value
        copiedPartyWithoutId.firstName ||= ""
        copiedPartyWithoutId.middleName ||= ""
        copiedPartyWithoutId.lastName ||= ""
        copiedPartyWithoutId.busName ||= ""

        this.handlePartyAddedEvent(copiedPartyWithoutId);
    }

    handlePartyRemovedEvent(removedParty: Party) {
        if (removedParty.id) {
            this.partyRepo.deleteParty(`orders/${this.order.id}/parties/${removedParty.id}`)
                .subscribe(() => this.handleDeletedParty(removedParty));
        } else {
            this.handleDeletedParty(removedParty);
        }
    }

    handlePartyFormValueChangedEvent(party: Party) {
        let orderToValidate = this.copyOrder(this.order);
        orderToValidate.parties = [ party ];
        this.validate(orderToValidate);
      }

    private handleDeletedParty(removedParty: Party) {
        const index = this.order.parties!.findIndex(obj => obj.id === removedParty.id || !obj.id);

        // as long as our index was found 
        if (index != -1) {
            this.order.parties!.splice(index, 1);
            this.orderManager.updateOrder(this.order);
            this.parties$.next(this.getParties());
        }
    }

    protected createParty(party: Party): Observable<Party> {
        return this.partyRepo.createParty(`orders/${this.order.id}/parties`, party)
            .pipe(
                map(result => ((result as any).resource) as Party),
                tap(party => {
                    this.order.parties!.push(party);
                    this.orderManager.updateOrder(this.order);
                    this.parties$.next(this.getParties());
                }));
    }

    protected updateParty(party: Party) {
        this.partyRepo.updateParty(`orders/${this.order.id}/parties/${party.id}`, party)
            .subscribe(result => {
                const party = ((result as any).resource) as Party;
                this.replaceParty(party);
            });
    }

    protected replaceParty(party: Party) {
        const index = this.order.parties!.findIndex(obj => obj.id === party.id);

        // as long as our index was found 
        if (index != -1) {
            this.order.parties![index] = party;
            this.orderManager.updateOrder(this.order);
            this.parties$.next(this.getParties());
        }
    }

    protected static parseErrorsForValidationState(errors: ValidationProblem[] | undefined, order: Order, filterPartyType: (partyTypeID: ePartyType) => boolean): StepValidationState {
        const debtors = order.parties?.filter(party => filterPartyType(party.partyTypeID));

        if (debtors?.length === 0) {
          return StepValidationState.ValidationError;
        }
    
        const partyValidationErrors = errors?.filter(err => err.path.startsWith('parties'));
    
        return partyValidationErrors?.some(err => {
          const partyIndex = Number(err.path.split('/').at(1));
    
          if(Number.isNaN(partyIndex) || partyIndex < 0) {
            return false;
          }
    
          const party = order.parties?.at(partyIndex);
    
          if(!party) {
            return false;
          }
    
          return filterPartyType(party?.partyTypeID);
        }) ? StepValidationState.ValidationError : StepValidationState.ValidationSuccess;
    }
}