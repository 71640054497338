<mat-card>
    <mat-card-content>
        <form [formGroup]="_filterForm">
            <div class="flex-container">
                <mat-form-field class="form-field">
                    <mat-select formControlName="orderTypeID" placeholder="Order Type">
                        <mat-option *ngFor="let option of _orderTypes" [value]="option.value">
                            {{ option.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <lib-jurisdiction-selection (formReady)="jurisdictionFormChanges($event)" [defaultCountry]="'CA'"></lib-jurisdiction-selection>

            <div>
                <button mat-raised-button color="accent" class="margin-sides-5" aria-label="Clear filters button"
                    (click)="onClear()" [disabled]="!_filterForm.dirty">
                    Clear Filters
                </button>
                <button mat-raised-button color="accent" class="margin-sides-5" aria-label="Filter button" type="submit"
                    (click)="onFilter()">
                    Filter
                </button>
            </div>
        </form>
    </mat-card-content>
</mat-card>