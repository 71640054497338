<form [formGroup]="lienDetails">
    <div *ngIf="uiConfiguration.dischargeDetailsConfiguration?.showDischargeType">
        <div class="flex-container">
            <div class="flex-item form-field">
                <mat-radio-group [formControlName]="'dischargeType'" aria-label="Discharge type radio option">
                    <mat-radio-button data-testid="total-discharge-radio-button" class="radio-button" [value]="totalDischarge">Total Discharge</mat-radio-button>
                    <mat-radio-button data-testid="agreement-to-discharge-radio-button" class="radio-button" [value]="agreementToDischarge">Agreement to Discharge</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </div>

    <app-term [uiConfiguration]="uiConfiguration.lienDetailsConfiguration!" [errors$]="observableErrors$"
            [order]="order" [isDisabled]="isDisabled" (termFormValueChangedEvent)="handleTermFormValueChanged($event)"></app-term>
            
    <div class="flex-container" *ngIf="uiConfiguration.referenceRegistrationInformationUIConfiguration?.showRegistrationNumber">
        <div class="validated-form-field-container flex-container">
            <mat-form-field class="flex-item form-field" appearance="outline">
                <mat-label>Registration Number</mat-label>
                <input matInput data-testid="registration-number-field" placeholder="Registration Number"
                    [formControlName]="'registrationNumber'" libReplaceCharacter>
            </mat-form-field>
            <lib-error-bubble [control]="lienDetails.controls['registrationNumber']"
                [message]="registrationNumberError$"></lib-error-bubble>
        </div>
    </div>

    <div class="flex-container"
        *ngIf="uiConfiguration.lienDetailsConfiguration?.showFileNumber || uiConfiguration.referenceRegistrationInformationUIConfiguration?.showFileNumber">
        <div class="validated-form-field-container flex-container">
            <mat-form-field class="flex-item form-field" appearance="outline">
                <mat-label>File Number</mat-label>
                <input matInput data-testid="file-number-field" placeholder="File Number" [formControlName]="'fileNumber'">
            </mat-form-field>
            <lib-error-bubble [control]="lienDetails.controls['fileNumber']"
                [message]="fileNumberError$"></lib-error-bubble>
        </div>
    </div>

    <div class="flex-container" *ngIf="uiConfiguration.referenceRegistrationInformationUIConfiguration?.showRIN">
        <div class="validated-form-field-container flex-container">
            <mat-form-field class="flex-item form-field" appearance="outline">
                <mat-label>RIN</mat-label>
                <input matInput data-testid="rin-field" placeholder="RIN"
                    [formControlName]="'rin'">
            </mat-form-field>
            <lib-error-bubble [control]="lienDetails.controls['rin']"
                [message]="rinError$"></lib-error-bubble>
        </div>
    </div>

    <div class="flex-container padding-top" *ngIf="uiConfiguration.referenceRegistrationInformationUIConfiguration?.showOriginalQCFormTypeID">
        <mat-form-field class="flex-item form-field" appearance="outline">
            <mat-label>Original Form Type</mat-label>
            <mat-select data-testid="qc-from-type-dropdown" [formControlName]="'originalQCFormTypeID'">
                <mat-option *ngFor="let qcFormType of qcFormTypes" [value]="qcFormType.value"
                    [attr.data-testid]="'form-type-option-' + qcFormType.label.split(' ')[0].toLowerCase()">
                {{qcFormType.label}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="flex-container padding-top" *ngIf="uiConfiguration.referenceRegistrationInformationUIConfiguration?.showOriginalOrderTypeID">
        <mat-form-field class="flex-item form-field" appearance="outline">
            <mat-label>Original Registration Type</mat-label>
            <mat-select data-testid="original-order-type-dropdown" [formControlName]="'originalOrderTypeID'">
                <mat-option *ngFor="let orderType of orderTypes" [value]="orderType.value"
                    [attr.data-testid]="'order-type-option-' + orderType.value">
                {{orderType.label}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="flex-container" *ngIf="uiConfiguration.referenceRegistrationInformationUIConfiguration?.showOriginalSigningDate">
        <div class="validated-form-field-container flex-container">
            <mat-form-field class="form-field flex-item" appearance="outline">
                <mat-label>Original Signing Date</mat-label>
                <input matInput [matDatepicker]="originalSigningDate" data-testid="original-signing-date-field" formControlName="originalSigningDate"
                    placeholder="YYYY-MM-DD">
                <mat-datepicker-toggle matSuffix [for]="originalSigningDate"></mat-datepicker-toggle>
                <mat-datepicker #originalSigningDate></mat-datepicker>
            </mat-form-field>
            <lib-error-bubble [control]="lienDetails.get('originalSigningDate')!"
                [message]="originalSigningDateError$"></lib-error-bubble>
        </div>
    </div>
    
    <div class="flex-container" *ngIf="uiConfiguration.referenceRegistrationInformationUIConfiguration?.showOriginalExpiryDate && showCurrentExpiryDate">
        <div class="validated-form-field-container flex-container">
            <mat-form-field class="form-field flex-item" appearance="outline">
                <mat-label>Current Expiry Date</mat-label>
                <input matInput [matDatepicker]="currentExpiryDate" data-testid="current-expiry-date-field" formControlName="currentExpiryDate"
                    placeholder="YYYY-MM-DD">
                <mat-datepicker-toggle matSuffix [for]="currentExpiryDate"></mat-datepicker-toggle>
                <mat-datepicker #currentExpiryDate></mat-datepicker>
            </mat-form-field>
            <lib-error-bubble [control]="lienDetails.get('currentExpiryDate')!"
                [message]="currentExpiryDateError$"></lib-error-bubble>
        </div>
    </div>
</form>