import { Component } from '@angular/core';
import { Constants } from 'projects/reg-hub-client/src/constants';
import { Party, OrderManagerService, ValidationService, ValidationProblem, Order, UIConfigurationService, ePartyType } from 'reg-hub-common';
import { PartyRepositoryService } from 'reg-hub-common';
import { StepValidationState } from '../../../interfaces/step';
import { PartiesComponent } from '../parties/parties.component';

@Component({
  selector: 'app-dealers',
  templateUrl: './dealers.component.html',
  styleUrls: ['./dealers.component.css']
})
export class DealersComponent extends PartiesComponent {
  protected businessDealer = ePartyType.businessDealer;
  protected individualDealer = ePartyType.individualDealer;
  
  constructor(
    partyRepo: PartyRepositoryService,
    uiConfigurationService: UIConfigurationService,
    orderManager: OrderManagerService,
    validationService: ValidationService) {
    super(partyRepo, uiConfigurationService, orderManager, validationService);
  }

  public override getValidationState(errors: ValidationProblem[] | undefined, order: Order): StepValidationState {
    return PartiesComponent.parseErrorsForValidationState(errors, order, Constants.isDealer);
  }

  protected override getParties(): Party[] {
    return this.order.parties!.filter((party) => Constants.isDealer(party.partyTypeID));
  }
}
