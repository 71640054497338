<div class="padding">
    <div class="flex-container" *ngIf="!isLoadingOrder">
        <div class="flex-item" *ngIf="!isLoadingOrder">
            <div *ngIf="this.orderId" class="padding-sides-15 padding-bottom">
                <mat-card>
                    <mat-card-content>
                        <app-base-order-information-review
                            [orderTypeGroupID]="orderTypeGroupID"></app-base-order-information-review>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="padding-sides-15">
                <mat-card>
                    <mat-card-content>
                        <app-order-stepper [orderId]="orderId" [orderTypeGroupID]="orderTypeGroupID"
                            (promptSubmissionConfirmationEvent)="submissionConfirmation()"
                            (promptDeletionConfirmationEvent)="deletionConfirmation()"
                            (promptCancelConfirmationEvent)="cancelConfirmation()">
                        </app-order-stepper>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>

        <div class="flex-item-fixed-size padding-top margin-right-15 order-fees-sticky">
            <app-order-fees></app-order-fees>
            <br />
            <mat-card>
                <mat-card-content>
                    <div class="button-container" *ngIf="shouldShowOrderMessages()">
                        <a mat-flat-button color="primary" class="flex-item-right-align" matBadge="{{messageCount()}}"
                            matBadgeColor="warn" (click)="openOrderMessages()">Messages</a>
                    </div>
                    <div class="button-container">
                        <a *ngIf="order.orderStatusTypeID === 'Draft'" mat-flat-button (click)="deletionConfirmation()"
                            color="warn" class="apply-padding flex-item-right-align" aria-label="Delete draft button">
                            Delete Draft
                        </a>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>

    <!-- Spinner (replaces everything and waits until all requests have completed to show anything) -->
    <div *ngIf="this.isLoadingOrder" class="loading-spinner-overlay">
        <div class="loading-spinner-overlay">
            <mat-spinner></mat-spinner>
        </div>
    </div>
</div>
<!-- End of spinner -->