import { Component, Inject, Input, OnInit } from '@angular/core';
import { EnumeratedTypesRepositoryService, eOrderType, eQCFormType, JurisdictionInfo, Order, OrderRepositoryService, SelectOption } from 'reg-hub-common';
import { EnvironmentUrlService } from '../../services/environment-url/environment-url.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-duplicate-in',
  templateUrl: './duplicate-in.component.html',
  styleUrls: ['./duplicate-in.component.css']
})
export class DuplicateInComponent implements OnInit {
  @Input() order!: Order

  protected jurisdictions: JurisdictionInfo[] = [];
  protected qcFormTypes: SelectOption[] = [];

  protected selectedJurisdiction: string | null | undefined;
  protected selectedQcForm: string | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogOrder: Order,
    private router: Router,
    private enumeratedTypesRepo: EnumeratedTypesRepositoryService,
    private environmentUrl: EnvironmentUrlService,
    private orderRepositoryService: OrderRepositoryService) {
    this.order = dialogOrder;
  }

  ngOnInit(): void {
    this.enumeratedTypesRepo.getOrderTypeJurisdictions(this.environmentUrl.urlAddress, [ this.order.orderTypeID ])
      .subscribe(orderTypeJurisdictions => this.jurisdictions = orderTypeJurisdictions
        .at(0)?.jurisdictions?.filter(info => info.jurisdictionCode === 'QC' ? true : info.jurisdictionCode !== this.order.jurisdiction) ?? []);
  }

  protected handleJurisdictionChangeEvent() {
    if (this.showQcFormTypeControl()) {
      var lienFormTypes: eQCFormType[] = [eQCFormType.RDd, eQCFormType.RDe, eQCFormType.RHa, eQCFormType.RDf];

      this.enumeratedTypesRepo.getQcFormTypesAsSelectOptions(this.environmentUrl.urlAddress, lienFormTypes)
        .subscribe(qcFormTypes => {
          this.qcFormTypes = qcFormTypes
            .filter(form => this.order.jurisdiction === 'QC' && this.order.lien?.qcFormTypeID ? form.value !== this.order.lien.qcFormTypeID : true)
            .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
        })
    } else {
      this.selectedQcForm = null;
    }
  }

  showQcFormTypeControl(): boolean {
    return this.selectedJurisdiction === 'QC';
  }

  onDuplicateClick() {
    if(this.selectedJurisdiction) {
      this.orderRepositoryService.duplicateOrder(this.order.id, this.selectedJurisdiction, this.selectedQcForm as eQCFormType)
        .subscribe((result: any) => this.router.navigate([ 'orders', result.resource.id ]));
    }
  }

  getOrderType(): string {
    if (this.order.orderTypeID === eOrderType.lien ||
      this.order.orderTypeID === eOrderType.repairLien) {
      return this.order.orderType.name;
    } else {
      return this.order.referenceRegistrationInformation?.originalOrderTypeID?.toString() ?? '';
    }
  }

  shouldDisableDuplicateButton(): boolean {
    if(this.selectedJurisdiction === 'QC') {
      return !this.selectedQcForm;
    } else {
      return !this.selectedJurisdiction
    }
  }
}
