<div class="flex-container" *ngIf="!isLoadingOrder">
    <div class="flex-item">
        <div class="padding-sides-15">
            <app-order-details [order]="order" [uiConfiguration]="uiConfiguration" [loading]="isLoadingOrder">
            </app-order-details>
        </div>
    </div>

    <div class="flex-item-fixed-size padding-top margin-right-15 order-fees-sticky">
        <app-order-fees></app-order-fees>
        <br />
        <mat-card>
            <mat-card-content>
                <div class="button-container" *ngIf="showPrintButton()">
                    <a mat-flat-button color="primary" class="flex-item-right-align"
                        (click)="print()"><mat-icon>print</mat-icon> Print</a>
                </div>
                <div class="button-container">
                    <a mat-flat-button color="primary" class="flex-item-right-align"
                        (click)="openOrderHistory()">Order Group History</a>
                </div>
                <div class="button-container">
                    <a mat-flat-button color="primary" class="flex-item-right-align"
                        (click)="openValidationLogs()">Validation Logs</a>
                </div>
                <div class="button-container">
                    <a mat-flat-button color="primary" class="flex-item-right-align"
                    matBadge="{{messageCount()}}" matBadgeColor="warn"
                        (click)="openOrderMessages()">Messages</a>
                </div>
            </mat-card-content>
        </mat-card>
        <br />
        <app-actions
            *ngIf="isActionable()"
            [order]="order" [uiConfiguration]="uiConfiguration.actionUIConfiguration!"></app-actions>
    </div>
</div>

<!-- Spinner (replaces everything and waits until all requests have completed to show anything) -->
<div *ngIf="this.isLoadingOrder" class="loading-spinner-overlay">
</div>
<!-- End of spinner -->