<mat-card>
    <mat-card-content>
        <div class="button-container" *ngIf="showDuplicateButton()">
            <a mat-flat-button color="primary" (click)="duplicate()">Duplicate</a>
        </div>
        <div class="button-container" *ngIf="showUpdateButton()">
            <a mat-flat-button color="primary" (click)="update()" data-testid="order-actions-update">
                <mat-icon>replay</mat-icon> Update</a>
        </div>
        <div class="button-container" *ngIf="order.orderStatusTypeID == 'Historical'">
            <a mat-flat-button color="primary" (click)="edit()" data-testid="order-actions-edit">Edit</a>
        </div>
        <div class="button-container" *ngIf="uiConfiguration.hasAmendments && isActionable()">
            <a mat-flat-button color="primary" (click)="amend()" data-testid="order-actions-amend">Amend</a>
        </div>
        <div class="button-container"
            *ngIf="!order.lien?.isInfiniteTerm && uiConfiguration.hasRenewals && isActionable()">
            <a mat-flat-button color="primary" (click)="renew()" data-testid="order-actions-renew">Renew</a>
        </div>
        <div class="button-container" *ngIf="uiConfiguration.hasDischarges && isActionable()">
            <a mat-flat-button color="warn" (click)="discharge()" data-testid="order-actions-discharge">Discharge</a>
        </div>
    </mat-card-content>
</mat-card>