<div class="padding" *ngIf="hasAccess">
    <h1 class="left-box">{{fullName}}</h1>

    <mat-card *ngIf="this.reusableParty">
        <mat-card-content>
            <div class="flex-container">
                <mat-form-field appearance="outline" class="form-field">
                    <mat-label>Party Type</mat-label>
                    <mat-select [(ngModel)]="this.reusableParty.partyTypeID" [disabled]="!isEditable">
                        <mat-option *ngFor="let partyType of partyTypes" [value]="partyType.value">
                            {{partyType.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div *ngIf="!isBusiness()">
                    <mat-checkbox [(ngModel)]="this.reusableParty.isEstate" style="margin-left: 1em;"
                        name="is-estate">
                        Estate
                    </mat-checkbox>
                </div>
                <mat-checkbox [(ngModel)]="this.reusableParty!.isVisible" [disabled]="!isEditable"
                    style="margin-left: 1em; margin-right: 0.5em;">Active</mat-checkbox>
            </div>

            <div class="flex-container">
                <mat-form-field appearance="outline" class="form-field" *ngIf="isBusiness(); else isPerson">
                    <mat-label>Name</mat-label>
                    <input matInput name="name" [(ngModel)]="this.reusableParty.busName" [disabled]="!isEditable"
                        placeholder="Name">
                </mat-form-field>
                <ng-template #isPerson>
                    <mat-form-field appearance="outline" class="form-field">
                        <mat-label>First Name</mat-label>
                        <input matInput name="name" [(ngModel)]="this.reusableParty.firstName"
                            [disabled]="!isEditable" placeholder="Name">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="form-field">
                        <mat-label>Middle Name</mat-label>
                        <input matInput name="name" [(ngModel)]="this.reusableParty.middleName"
                            [disabled]="!isEditable" placeholder="Name">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="form-field">
                        <mat-label>Last Name</mat-label>
                        <input matInput name="name" [(ngModel)]="this.reusableParty.lastName" [disabled]="!isEditable"
                            placeholder="Name">
                    </mat-form-field>
                </ng-template>
            </div>

            <app-client-contact-details-card [contactDetails]="reusableParty.contactDetails!"
                [enabled]="isEditable"></app-client-contact-details-card>

            <div class="flex-container" *ngIf="isEditable; else isStatic">
                <button
                    mat-raised-button
                    (click)="cancel()"
                    class="apply-padding"
                    aria-label="Cancel button"
                    [class.spinner]="this.isEditing"
                    [disabled]="this.isEditing">
                    Cancel
                </button>
                <span class="spacer"></span>
                <button mat-raised-button (click)="updateReusableParty()" color="accent" class="apply-padding"
                    aria-label="Save billing details button" [class.spinner]="this.isEditing"
                    [disabled]="this.isEditing">
                    Save
                </button>
            </div>
            <ng-template #isStatic>
                <h3>Registry Codes</h3>
                <table mat-table [dataSource]="this.reusableParty.reusablePartyCodes!">
                    <ng-container matColumnDef="country">
                        <th mat-header-cell *matHeaderCellDef> Country </th>
                        <td mat-cell *matCellDef="let party"> {{party.country}} </td>
                    </ng-container>

                    <ng-container matColumnDef="jurisdiction">
                        <th mat-header-cell *matHeaderCellDef> Jurisdiction </th>
                        <td mat-cell *matCellDef="let party"> {{party.jurisdiction}} </td>
                    </ng-container>

                    <ng-container matColumnDef="code">
                        <th mat-header-cell *matHeaderCellDef> Registry Code </th>
                        <td mat-cell *matCellDef="let party"> {{party.code}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedPartyCodeColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedPartyCodeColumns;"></tr>
                </table>
            </ng-template>
        </mat-card-content>
    </mat-card>
</div>