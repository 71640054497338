<div class="padding">
    <form [formGroup]="lienDetails">
        <app-term [uiConfiguration]="uiConfiguration.lienDetailsConfiguration!" [errors$]="observableErrors$" [order]="order" [isDisabled]="isDisabled"></app-term>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showAmount" class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Amount</mat-label>
                    <input matInput data-testid="amount-field" placeholder="Amount" [formControlName]="'amount'" type="number">
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.controls['amount']"
                    [message]="amountError$"></lib-error-bubble>
            </div>
        </div>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showDateOfLien" class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="form-field flex-item" appearance="outline">
                    <mat-label>Date of Lien</mat-label>
                    <input matInput [matDatepicker]="dateOfLien" data-testid="date-of-lien-field" formControlName="dateOfLien"
                        placeholder="YYYY-MM-DD">
                    <mat-datepicker-toggle matSuffix [for]="dateOfLien"></mat-datepicker-toggle>
                    <mat-datepicker #dateOfLien></mat-datepicker>
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.get('dateOfLien')!"
                    [message]="dateOfLienError$"></lib-error-bubble>
            </div>
        </div>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showAdditionalInformation" class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Additional Information</mat-label>
                    <textarea matInput data-testid="additional-information-field" [formControlName]="'additionalInformation'"
                        placeholder="Additional information"></textarea>
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.controls['additionalInformation']"
                    [message]="additionalInformationError$"></lib-error-bubble>
            </div>
        </div>
    </form>
</div>