<mat-sidenav-container>
    <mat-sidenav [opened]="isSidenavOpen" mode="side" role="navigation">
        <div class="mat-sidenav-wrapper">
            <div class="mat-nav-wrapper">
                <mat-nav-list>
                    <a *ngFor="let item of navItems" [routerLink]="item.link" class="inactiveRow" routerLinkActive="activeRow"
                        [attr.data-testid]="'side-menu-option-' + item.label.replaceAll(' ', '-').toLowerCase()">
                        <div class="list-item" matRipple>
                            <span class="list-item-icon" routerLinkActive="activeIcon"><mat-icon>{{ item.icon }}</mat-icon></span>
                            <span class="list-item-label" routerLinkActive="activeText"
                                matBadge="{{ item.label === 'Messages' ? '!' : '' }}"
                                matBadgeSize="small" matBadgeOverlap="false"
                                [matBadgeHidden]="item.label === 'Messages' && unreadMessageCount < 1">
                                {{ item.label }}
                            </span>
                        </div>
                    </a>
                </mat-nav-list>
                <mat-divider></mat-divider>
                <div mat-subheader class="section-subheader">NEW REQUEST</div>
                <mat-nav-list>
                    <a *ngFor="let item of requestItems" [routerLink]="item.link" class="inactiveRow" routerLinkActive="activeRow"
                        [attr.data-testid]="'side-menu-option-' + item.label.replaceAll(' ', '-').toLowerCase()">
                        <div class="list-item" matRipple>
                            <span class="list-item-icon" routerLinkActive="activeIcon"><mat-icon>{{ item.icon }}</mat-icon></span>
                            <span class="list-item-label" routerLinkActive="activeText">{{ item.label }}</span>
                        </div>
                    </a>
                </mat-nav-list>
                <mat-divider></mat-divider>
                <div mat-subheader class="section-subheader">HELP</div>
                <mat-nav-list>
                    <a *ngFor="let item of helpItems" [routerLink]="item.link" class="inactiveRow" routerLinkActive="activeRow"
                        [attr.data-testid]="'side-menu-option-' + item.label.replaceAll(' ', '-').toLowerCase()">
                        <div class="list-item" matRipple>
                            <span class="list-item-icon" routerLinkActive="activeIcon"><mat-icon>{{ item.icon }}</mat-icon></span>
                            <span class="list-item-label" routerLinkActive="activeText">{{ item.label }}</span>
                        </div>
                    </a>
                </mat-nav-list>
            </div>
        </div>
    </mat-sidenav>

    <mat-sidenav-content>
        <div toastContainer aria-live="polite"></div>
        <div class="spacer-small"></div>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>