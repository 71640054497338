import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Order } from '../../interfaces/orders/order';
import { Asset } from '../../interfaces/assets/asset';
import { eLogSeverity, eOrderType, eSerialNumberActivityLevel } from '../../enums';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SelectOption } from '../../interfaces/select-option';
import { VinDecoderService } from '../../services/vin-decoder/vin-decoder.service';
import { VinDecodeResult } from '../../interfaces/vin-decode/vin-decode-result';
import { VinDecodeResultValue } from '../../interfaces/vin-decode/vin-decode-result-value';
import { ToastrService } from 'ngx-toastr';
import { OrderLogForCreation } from '../../interfaces/order-logs/order-log-for-creation';
import { BehaviorSubject, Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { ValidationProblem } from '../../interfaces/validation/validation-problem';
import { EnumeratedTypesRepositoryService } from '../../services/enumerated-types/enumerated-types-repository.service';
import { AssetUIConfiguration } from '../../interfaces/ui-configurations/asset-ui-configuration';
import { ConsortiumService } from '../../services/consortium/consortium.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'lib-handle-asset',
  templateUrl: './handle-asset.component.html',
  styleUrls: ['./handle-asset.component.css'],
  animations: [
    trigger('slideDown', [
      state('hidden', style({
        margin: '0px',
        padding: '0px',
        height: '0px',
        opacity: 0
      })),
      state('visible', style({
        margin: '*',
        padding: '*',
        height: '*',
        opacity: 1
      })),
      transition('hidden <=> visible', [
        animate('0.5s cubic-bezier(0.5, 0.1, 0.1, 1)')
      ])
    ])
  ]
})
export class HandleAssetComponent implements OnInit, OnDestroy {
  @Input() order!: Order;
  @Input() errors$!: Observable<ValidationProblem[] | undefined>;

  @Input() asset!: Asset;
  @Input() uiConfiguration!: AssetUIConfiguration;

  @Output() assetSavedEvent = new EventEmitter<Asset>();
  @Output() assetRemovedEvent = new EventEmitter<Asset>();
  @Output() changesCancelledEvent = new EventEmitter<Asset>();
  @Output() vinDecodedEvent = new EventEmitter<OrderLogForCreation>();
  @Output() assetFormValueChangedEvent = new EventEmitter<Asset>();

  assetFormValueChangedSubscription: Subscription | undefined;
  errorsSubscription$: Subscription | undefined;

  private onDestroy$ = new Subject<void>();

  assetTypeIdError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  manufacturedHomeRegistrationNumberError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  serialNumberError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  makeError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  modelError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  yearError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  colorError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);

  protected assetDetails!: FormGroup;
  protected isDecodingSerialNumber: boolean = false;
  protected manufacturedHomeAssetType = "ManufacturedHome";
  protected cleanedErrorList: string[] = [];

  protected activityLevel: eSerialNumberActivityLevel | undefined;

  assetTypes: SelectOption[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private vinDecoder: VinDecoderService,
    private messageService: ToastrService,
    private enumeratedTypesRepository: EnumeratedTypesRepositoryService,
    private consortiumService: ConsortiumService) { }

  private initForms() {
    this.assetDetails = this.formBuilder.group({
      id: [this.asset.id],
      assetTypeID: [this.asset.assetTypeID],
      serialNumber: [this.asset.serialNumber],
      make: [this.asset.make],
      model: [this.asset.model],
      year: [this.asset.year],
      manufacturedHomeRegistrationNumber: [this.asset.manufacturedHomeRegistrationNumber],

      externalEntity: null
    })

    if(this.uiConfiguration.showColor) {
      this.assetDetails.addControl('color', this.formBuilder.control(this.asset.color));
    }

    this.enumeratedTypesRepository.getAssetTypesAsSelectOptions(this.order.orderType.id as eOrderType, this.order.jurisdiction).subscribe(types => {
      this.assetTypes = types;
      this.assetDetails.get('assetTypeID')?.setValue(this.asset.assetTypeID, { emitEvent: false });
    })

    this.assetDetails.get('assetTypeID')!.valueChanges.subscribe(value => {
      this.assetDetails.get('manufacturedHomeRegistrationNumber')!.setValue(null);
    });

    // if(this.asset.serialNumber) {
    //   this.checkActivityLevel(this.asset.serialNumber);
    // }
    
    // this.assetDetails.get('serialNumber')!.valueChanges
    //   .pipe(takeUntil(this.onDestroy$))
    //   .subscribe(serialNumber => this.checkActivityLevel(serialNumber));

    this.assetDetails.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.assetFormValueChangedEvent.emit(this.assetDetails.value));

    if(this.errors$) {
      this.errors$
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(errors => this.pushErrors(errors));
    }
  }

  ngOnInit(): void {
    this.initForms();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  clearFields() {
    this.assetDetails.reset();
    this.assetDetails.setErrors(null);
    this.assetDetails.markAsPristine();
    this.assetDetails.markAsUntouched();
    this.assetDetails.updateValueAndValidity();
  }

  removeAsset() {
    this.assetRemovedEvent.emit(this.assetDetails.value);
  }

  saveAsset() {
    this.assetSavedEvent.emit(this.assetDetails.value);
  }

  cancelChanges() {
    this.changesCancelledEvent.emit(this.asset);
  }
  
  pushErrors(errors: ValidationProblem[] | undefined) {
    if(!errors) {
      return;
    }

    this.assetTypeIdError$.next(errors?.filter(error => error.path.includes('/assettypeid')).at(0)?.userFriendlyMessage);
    this.manufacturedHomeRegistrationNumberError$.next(errors?.filter(error => error.path.includes('/manufacturedhomeregistrationnumber')).at(0)?.userFriendlyMessage);
    this.serialNumberError$.next(errors?.filter(error => error.path.includes('/serialnumber')).at(0)?.userFriendlyMessage);
    this.makeError$.next(errors?.filter(error => error.path.includes('/make')).at(0)?.userFriendlyMessage);
    this.modelError$.next(errors?.filter(error => error.path.includes('/model')).at(0)?.userFriendlyMessage);
    this.yearError$.next(errors?.filter(error => error.path.includes('/year')).at(0)?.userFriendlyMessage);
    this.colorError$.next(errors?.filter(error => error.path.includes('/color')).at(0)?.userFriendlyMessage);
  }

  decodeVin() {
    this.isDecodingSerialNumber = true;
    this.vinDecoder.decode(this.assetDetails.get('serialNumber')!.value)
    .subscribe(
      {
        next: (decodedVin) => {
          if (decodedVin != null && decodedVin.Count > 0) {
            var errorCodes = this.findErrorCodesInResults(decodedVin);
            var errorText = this.findErrorTextInResults(decodedVin);

            // Error code of 0 is a success state
            if (errorCodes?.Value != "0") {
              this.parseAndShowErrors(errorText?.Value ?? "");
            } else {
              this.cleanedErrorList = [];
            }

            var make = this.findMakeInResults(decodedVin);
            var model = this.findModelInResults(decodedVin);
            var year = this.findYearInResults(decodedVin);
            var vehicleType = this.findVehicleTypeInResults(decodedVin);
    
            this.assetDetails.patchValue({
              make: make?.Value,
              model: model?.Value,
              year: year?.Value,
            });
          }

          this.isDecodingSerialNumber = false;

          const orderLog: OrderLogForCreation = {
            message: `DECODE USED, VEHICLE TYPE: ${vehicleType?.Value}`,
            detailedMessage: "",
            source: "RegHubClient",
            username: "",
            logSeverityID: eLogSeverity.Information
          }
          this.vinDecodedEvent.emit(orderLog);
        },
        error: _ => {
          this.messageService.error("Failed to decode Serial Number", '', { enableHtml: true });
          this.isDecodingSerialNumber = false;  // Stop loading on error
        }
    });
  }

  findMakeInResults(vinDecodeResult: VinDecodeResult): VinDecodeResultValue | undefined {
    return vinDecodeResult.Results.find((result: { Variable: string; }) => result.Variable === "Make");
  }

  findModelInResults(vinDecodeResult: VinDecodeResult): VinDecodeResultValue | undefined {
    return vinDecodeResult.Results.find((result: { Variable: string; }) => result.Variable === "Model");
  }

  findYearInResults(vinDecodeResult: VinDecodeResult): VinDecodeResultValue | undefined {
    return vinDecodeResult.Results.find((result: { Variable: string; }) => result.Variable === "Model Year");
  }

  findVehicleTypeInResults(vinDecodeResult: VinDecodeResult): VinDecodeResultValue | undefined {
    return vinDecodeResult.Results.find((result: { Variable: string; }) => result.Variable === "Vehicle Type");
  }

  findErrorCodesInResults(vinDecodeResult: VinDecodeResult): VinDecodeResultValue | undefined {
    return vinDecodeResult.Results.find((result: { Variable: string; }) => result.Variable === "Error Code");
  }

  findErrorTextInResults(vinDecodeResult: VinDecodeResult): VinDecodeResultValue | undefined {
    return vinDecodeResult.Results.find((result: { Variable: string; }) => result.Variable === "Error Text");
  }

  parseAndShowErrors(errorText: string) {
    this.cleanedErrorList = [];
    var errorList = errorText.split(";");

    for (let error of errorList) {
      error = error.trim();

      if (!error.startsWith("7") && !error.startsWith("Please")) {
        const cleanedText = error.replace(/\b\d+\s-\s/g, "");
        this.cleanedErrorList.push(cleanedText);
      }
    }

    this.isDecodingSerialNumber = false;
  }

  getAsset() {
    return this.assetDetails.value;
  }
  
  setAsset(asset: Asset) {
    this.asset = asset;

    this.initForms()
  }

  // checkActivityLevel(serialNumber: string): void {
  //   if(!serialNumber) {
  //     this.activityLevel = undefined;

  //     return;
  //   }

  //   if (serialNumber.length > 12) {
  //     this.consortiumService.getSerialNumberActivityLevel(serialNumber.trim(), this.order.jurisdiction).subscribe(result => this.activityLevel = result.activityLevel);
  //   } else {
  //     this.activityLevel = undefined;
  //   }
  // }

  // isGreenActivityLevel() {
  //   return this.activityLevel === eSerialNumberActivityLevel.Green;
  // }

  // isYellowActivityLevel() {
  //   return this.activityLevel === eSerialNumberActivityLevel.Yellow;
  // }

  // isRedActivityLevel() {
  //   return this.activityLevel === eSerialNumberActivityLevel.Red;
  // }
}
