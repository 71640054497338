<div class="padding">
    <form [formGroup]="lienDetails">
        <app-term [uiConfiguration]="uiConfiguration.lienDetailsConfiguration!" [errors$]="observableErrors$"
            [order]="order" [isDisabled]="isDisabled"></app-term>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showAmount" class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Amount</mat-label>
                    <input matInput data-testid="amount-field" placeholder="Amount" type="number" [formControlName]="'amount'">
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.controls['amount']"
                    [message]="amountError$"></lib-error-bubble>
            </div>
        </div>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showMaturityDate" class="flex-container padding-top">
            <div class="flex-item form-field">
                <mat-radio-group formControlName="maturityDateOptions" name="maturity-date-selection"
                    aria-label="Lien maturity date or no maturity date button">
                    <mat-radio-button class="radio-button" data-testid="no-fixed-maturity-date-radio-button"
                        [value]="noFixedMaturityDate">No Fixed
                        Maturity Date</mat-radio-button>
                    <mat-radio-button class="radio-button" data-testid="maturity-date-radio-button"
                        [value]="maturityDate">Maturity
                        Date</mat-radio-button>
                    <mat-radio-button class="radio-button" data-testid="no-maturity-date-radio-button"
                        [value]="noMaturityDateInformation">No Maturity Date Information Provided</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div *ngIf="showMaturityDate()" class="flex-container padding-top">
            <mat-form-field class="form-field flex-item" appearance="outline">
                <mat-label>Maturity Date</mat-label>
                <input matInput [matDatepicker]="maturityDate" data-testid="maturity-date-field" placeholder="Maturity Date"
                    [formControlName]="'maturityDate'">
                <mat-datepicker-toggle matSuffix [for]="maturityDate"></mat-datepicker-toggle>
                <mat-datepicker #maturityDate></mat-datepicker>
            </mat-form-field>
        </div>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showCautionFiling"
            class="flex-container flex-item padding-top">
            <div class="validated-form-field-container flex-container">
                <mat-checkbox class="flex-item" [formControlName]="'cautionFiling'" data-testid="caution-filing-checkbox">
                    Caution Filing
                </mat-checkbox>
                <lib-error-bubble [control]="lienDetails.controls['cautionFiling']"
                    [message]="cautionFilingError$"></lib-error-bubble>
            </div>
        </div>
    </form>
</div>