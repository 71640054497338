<mat-card *ngIf="orderFees">
    <mat-card-header>
        <mat-card-title>Order Summary</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <table mat-table [dataSource]="orderFees" class="mat-elevation-z8">
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef> Description </th>
              <td mat-cell *matCellDef="let fee"> <span *ngIf="fee.id == emptyGuid" class="expected">* </span>{{getDescription(fee)}} </td>
              <td mat-footer-cell *matFooterCellDef class="total"> <span *ngIf="!isComplete" class="expected">* </span>Total: </td>
            </ng-container>
        
            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef> Amount </th>
              <td mat-cell *matCellDef="let fee"> {{(fee.amount) | currency}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="tax">
              <th mat-header-cell *matHeaderCellDef> Tax </th>
              <td mat-cell *matCellDef="let fee"> {{(fee.gst + fee.pst + fee.hst) | currency}} </td>
              <td mat-footer-cell *matFooterCellDef class="total"> {{ totalCost | currency }} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
          </table>
          <p *ngIf="!isComplete" class="expected">* Expected</p>
    </mat-card-content>
</mat-card>