<div class="padding">
    <form [formGroup]="lienDetails">
        <div class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Registration Number</mat-label>
                    <input matInput data-testid="registration-number-field" placeholder="Registration Number"
                        [formControlName]="'registrationNumber'">
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.controls['registrationNumber']"
                    [message]="registrationNumberError$"></lib-error-bubble>
            </div>
        </div>

        <div  class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="form-field flex-item" appearance="outline">
                    <mat-label>Registration Date</mat-label>
                    <input matInput [matDatepicker]="registrationDate" data-testid="registration-date-field" formControlName="registrationDate"
                        placeholder="YYYY-MM-DD">
                    <mat-datepicker-toggle matSuffix [for]="registrationDate"></mat-datepicker-toggle>
                    <mat-datepicker #registrationDate></mat-datepicker>
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.get('registrationDate')!"
                    [message]="registrationDateError$"></lib-error-bubble>
            </div>
        </div>

        <app-term [uiConfiguration]="uiConfiguration.lienDetailsConfiguration!" [errors$]="observableErrors$"
            [order]="order" [isDisabled]="isDisabled"></app-term>
        
        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showAmount" class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Amount</mat-label>
                    <input matInput data-testid="amount-field" placeholder="Amount" [formControlName]="'amount'" type="number">
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.controls['amount']"
                    [message]="amountError$"></lib-error-bubble>
            </div>
        </div>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showPerfectionInAnotherJurisdiction ||
                    uiConfiguration.lienDetailsConfiguration?.showPurchaseMoneySecurityInterest" class="flex-container flex-item padding-top">
            <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showPerfectionInAnotherJurisdiction" class="validated-form-field-container flex-container">
                <mat-checkbox class="flex-item" [formControlName]="'perfectionInAnotherJurisdiction'" data-testid="perfection-checkbox">
                    Perfection in Another Jurisdiction
                </mat-checkbox>
                <lib-error-bubble [control]="lienDetails.controls['perfectionInAnotherJurisdiction']"
                    [message]="perfectionInAnotherJurisdictionError$"></lib-error-bubble>
            </div>
            <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showPurchaseMoneySecurityInterest" class="validated-form-field-container flex-container">
                <mat-checkbox class="flex-item" [formControlName]="'purchaseMoneySecurityInterest'"
                    data-testid="security-interest-checkbox">
                    Purchase Money Security Interest
                </mat-checkbox>
                <lib-error-bubble [control]="lienDetails.controls['purchaseMoneySecurityInterest']"
                    [message]="purchaseMoneySecurityInterestError$"></lib-error-bubble>
            </div>
        </div>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showTrustIndenture ||
                    uiConfiguration.lienDetailsConfiguration?.showReceiverAppointment" class="flex-container flex-item padding-top">
            <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showTrustIndenture"  class="validated-form-field-container flex-container">
                <mat-checkbox class="flex-item" [formControlName]="'trustIndenture'" data-testid="trust-indenture-checkbox">
                    Trust Indenture
                </mat-checkbox>
                <lib-error-bubble [control]="lienDetails.controls['trustIndenture']"
                    [message]="trustIndentureError$"></lib-error-bubble>
            </div>
            <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showReceiverAppointment"  class="validated-form-field-container flex-container">
                <mat-checkbox class="flex-item" [formControlName]="'receiverAppointment'" data-testid="receiver-appointment-checkbox">
                    Receiver Appointment
                </mat-checkbox>
                <lib-error-bubble [control]="lienDetails.controls['receiverAppointment']"
                    [message]="receiverAppointmentError$"></lib-error-bubble>
            </div>
        </div>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showAdditionalInformation" class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Additional Information</mat-label>
                    <textarea matInput data-testid="additional-information-field" [formControlName]="'additionalInformation'"
                        placeholder="Additional information"></textarea>
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.controls['additionalInformation']"
                    [message]="additionalInformationError$"></lib-error-bubble>
            </div>
        </div>
    </form>
</div>