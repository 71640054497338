<div @expandHeight *ngIf="_events.length > 0" class="padding-sides-15">
    <div @expandHeight *ngFor="let event of _events; let i = index" class="container">
        <div (click)="closeEventNotification(i)" role="alert" class="error flex-item">
            <h3>
                <mat-icon aria-hidden="false" aria-label="Warning">warning</mat-icon>
                {{event.message}}
            </h3>
        </div>
        <button mat-icon-button class="close-button" (click)="closeEventNotification(i)">
            <mat-icon>close</mat-icon>
        </button>
    </div>

</div>