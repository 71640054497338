<div class="padding">
    <!-- Header Banner -->
    <h1 class="left-box">Information Documents</h1>

    <!-- Search Bar -->
    <div class="flex-container">
        <mat-form-field appearance="outline" class="form-field-full">
        <mat-label>Search Documents</mat-label>
        <input
            matInput
            (keyup)="filterDocuments($event)"
            placeholder="Type to search"
        />
        </mat-form-field>
    </div>

    <!-- Documents Grid -->
    <div class="flex-container flex-wrap">
        <div
        class="document-card"
        *ngFor="let infoDoc of informationDocuments"
        [@fadeIn]>
            <mat-card class="mat-elevation-z5">
                <!-- Thumbnail Image -->
                <mat-icon class="icon-display margin-top-bottom-25 margin-sides-25">description</mat-icon>
                
                <!-- Card Content -->
                <mat-card-content class="padding">
                    <mat-card-title class="card-title">{{ infoDoc.name }}</mat-card-title>
                    <mat-card-subtitle>{{ infoDoc.description }}</mat-card-subtitle>
                </mat-card-content>

                <!-- Card Actions -->
                <mat-card-actions class="card-actions">
                    <mat-card-footer class="margin-left-15">
                        <h5>Uploaded on: {{ infoDoc.added | date }}</h5>
                    </mat-card-footer>
                    <span class="spacer"></span>
                    <button mat-raised-button
                        color="primary"
                        class="prominent-button"
                        (click)="downloadDocument(infoDoc.id)">
                      Download
                    </button>
                  </mat-card-actions>
            </mat-card>
        </div>
    </div>
</div>