import { Injectable } from '@angular/core';
import { Params, UrlSerializer, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationHistoryService {
  private history: UrlTree[] = [];

  constructor(private parser: UrlSerializer) { }

  public add(currentUrl: string) {
    const parsedUrl = this.parser.parse(currentUrl);

    this.history.push(parsedUrl);

    if(this.history.length > 10) {
      this.history.shift();
    }
  }

  public getPreviousUrl(offset: number = 0): UrlTree {
    return this.history.at(-2 - offset) ?? this.parser.parse('/dashboard');
  }

  public getOrderDeletionRedirectUrl(deletedOrderID: string): UrlTree {
    const currentUrl = this.history.at(-1);
    let url = this.history.at(-2);

    const originatingOrderID = currentUrl?.queryParams['originatingOrderID'];

    if(originatingOrderID) {
      url = this.parser.parse(`/orders/${originatingOrderID}`);
    } else if(url?.toString()?.includes(deletedOrderID)) {
      url = this.history.at(-3);
    }

    if(!url) {
      return this.parser.parse('/dashboard');
    }

    return url;
  }
}
