<mat-expansion-panel [expanded]="autoExpand">
    <mat-expansion-panel-header>
        <mat-panel-title class="bold">
            Filters
        </mat-panel-title>
    </mat-expansion-panel-header>
    <form [formGroup]="_filterForm" class="padding">
        <div class="flex-container">
            <mat-form-field class="form-field flex-item">
                <mat-select formControlName="feeTypeID" placeholder="Fee Type">
                    <mat-option *ngFor="let option of feeTypes" [value]="option.value">
                        {{ option.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="form-field flex-item">
                <mat-label>Product Code</mat-label>
                <input matInput [formControlName]="'productCode'" placeholder="Product Code" />
            </mat-form-field>
            <mat-form-field class="form-field flex-item">
                <mat-label>Tax Code</mat-label>
                <input matInput [formControlName]="'taxCode'" placeholder="Tax Code" />
            </mat-form-field>
        </div>
        <div class="flex-container">
            <mat-form-field class="form-field flex-item">
                <mat-label>Order ID</mat-label>
                <input matInput [formControlName]="'orderID'" placeholder="Order ID" />
            </mat-form-field>
            <mat-form-field class="form-field flex-item">
                <mat-label>Date Added From</mat-label>
                <input matInput [matDatepicker]="addedDateFrom" [formControlName]="'addedDateFrom'"
                    placeholder="Date Added From">
                <mat-datepicker-toggle matSuffix [for]="addedDateFrom"></mat-datepicker-toggle>
                <mat-datepicker #addedDateFrom></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="form-field flex-item">
                <mat-label>Date Added To</mat-label>
                <input matInput [matDatepicker]="addedDateTo" [formControlName]="'addedDateTo'"
                    placeholder="Date Added To">
                <mat-datepicker-toggle matSuffix [for]="addedDateTo"></mat-datepicker-toggle>
                <mat-datepicker #addedDateTo></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="flex-container">
            <mat-form-field class="form-field flex-item">
                <mat-label>Amount From</mat-label>
                <input matInput [formControlName]="'amountFrom'" placeholder="Amount From" />
            </mat-form-field>
            <mat-form-field class="form-field flex-item">
                <mat-label>Amount To</mat-label>
                <input matInput [formControlName]="'amountTo'" placeholder="Amount To" />
            </mat-form-field>
        </div>
        <div>
            <button mat-raised-button color="accent" class="margin-sides-5" aria-label="Clear filters button"
                (click)="onClear()" [disabled]="!_filterForm.dirty">
                Clear Filters
            </button>
            <button mat-raised-button color="accent" class="margin-sides-5" aria-label="Filter button" type="submit"
                (click)="onFilter()">
                Filter
            </button>
        </div>
    </form>
</mat-expansion-panel>