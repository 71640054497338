import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { SystemEvent, SystemEventsHubService, SystemEventsRepositoryService } from 'reg-hub-common';
import {
    trigger,
    style,
    transition,
    animate,
} from '@angular/animations';
import { HttpParams } from '@angular/common/http';
import { interval } from 'rxjs';

@Component({
    selector: 'app-system-event-notification',
    templateUrl: './system-event-notification.component.html',
    styleUrls: ['./system-event-notification.component.css'],
    animations: [
        trigger('expandHeight', [
            transition(':enter', [
                style({ height: '0', opacity: 0 }),
                animate('250ms ease-in-out', style({ height: '*', opacity: 1 })),
            ]),
            transition(':leave', [
                animate('250ms ease-in-out', style({ height: '0', opacity: 0 })),
            ]),
        ]),
    ]
})
export class SystemEventNotificationComponent implements AfterViewInit, OnDestroy {
    protected _events: SystemEvent[] = [];
    protected _eventMessage: string | null = null;

    constructor(
        protected repo: SystemEventsRepositoryService,
        protected hub: SystemEventsHubService) { }

    ngAfterViewInit(): void {
        this.repo.getFirstPageSubscribable(new HttpParams()
            .append('isOpen', true)
            .append('isCustomerVisible', true)).subscribe(events => {
                this._events = events;
                this._eventMessage = events?.length > 0 ? events[0].message : null;
            });

        this.hub.connect();
        this.hub.events$.subscribe(events => {
            this._events = events;
            this._eventMessage = events?.length > 0 ? events[0].message : null;
        });

        //checking in memory events periodically for expired ones
        interval(10000).subscribe(() => {
            if (this._events.length > 0) {
                this._events = this._events.filter(event => {
                    if (!event.endDate) {
                        return true;
                    }

                    const now = new Date(); //supposedly always works with UTC internally
                    const endDate = new Date(event.endDate);

                    return now <= endDate;
                })
            }
        })
    }

    ngOnDestroy(): void {
        this.hub.disconnect();
    }

    closeEventNotification(index: number) {
        this._events.splice(index, 1);
    }
}