import { Component, Input } from '@angular/core';
import { base64StringToBlob } from 'blob-util';
import { Order, SearchResult } from 'reg-hub-common';
import { SearchResultsRepositoryService } from 'reg-hub-common';
import { EnvironmentUrlService } from '../../services/environment-url/environment-url.service';

@Component({
    selector: 'app-search-result',
    templateUrl: './search-result.component.html',
    styleUrls: ['./search-result.component.css']
})
export class SearchResultComponent {
    isSaving: boolean = false;
    @Input({ required: true }) order!: Order;
    searchResult?: SearchResult | null = null;

    constructor(private searchResultsRepo: SearchResultsRepositoryService){ }

    ngOnInit(): void {
        this.searchResult = this.order.searchResult;
    }

    hasResult(): boolean {
        return (this.searchResult != null);
    }

    hasResultDocument(): boolean {
        return (this.searchResult?.document != null);
    }

    hasSummaryDocument(): boolean {
        return (this.searchResult?.summaryDocument != null);
    }

    isComplete(): boolean {
        return (this.order.orderStatusTypeID == "Complete")
    }

    openResultDocument() {
        // Get Document
        this.searchResultsRepo.getSearchResultDocument(this.order.id)
            .subscribe(result => {
                const blob = base64StringToBlob(result.base64, "application/pdf");
                const blobURL = URL.createObjectURL(blob);
                // Open the document in a new tab instead of downloading
                window.open(blobURL, '_blank');
            });
    }

    openSummaryDocument() {
        // Get Document
        this.searchResultsRepo.getSearchResultSummaryDocument(this.order.id)
            .subscribe(result => {
                const blob = base64StringToBlob(result.base64, "application/pdf");
                const blobURL = URL.createObjectURL(blob);
                // Open the document in a new tab instead of downloading
                window.open(blobURL, '_blank');
            });
    }
}
