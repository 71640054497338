<h3>{{ isFormEnabled ? "Enter Report Criteria" : "Report Criteria Review" }}</h3>
<div class="padding">
    <form [formGroup]="searchCriteriaDetails">
        <div class="flex-container" *ngIf="order.businessReportCriteria?.businessName">
            <mat-list class="flex-item">
                <mat-list-item>
                    <div class="list-item-content">
                        <span class="label">Business Name:</span>
                        <span class="value">{{ order.businessReportCriteria?.businessName }}</span>
                    </div>
                </mat-list-item>
            </mat-list>
        </div>
        <div class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Business or Corporate Registration Number</mat-label>
                    <input matInput [formControlName]="'number'" name="number" placeholder="Business or Corporate Registration Number">
                </mat-form-field>
                <lib-error-bubble [control]="searchCriteriaDetails.get('number')!"
                    [message]="numberError$"></lib-error-bubble>
            </div>
        </div>
    </form>
</div>