import { AfterViewInit, Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BusinessSearchCriteria, BusinessSearchJurisdiction, EnumeratedTypesRepositoryService, JurisdictionInfo, Order, OrderManagerService, OrderTypeJurisdictions, ValidationProblem, ValidationService, eBusinessSearchCriteriaType, eOrderType } from 'reg-hub-common';
import { eCriteriaVariation } from '../../services/stepper/steps/search-stepper';
import { SteppedComponent } from 'projects/reg-hub-client/src/interfaces/stepped-component';
import { StepValidationState } from 'projects/reg-hub-client/src/interfaces/step';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { EnvironmentUrlService } from '../../services/environment-url/environment-url.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-business-search-criteria',
  templateUrl: './business-search-criteria.component.html',
  styleUrls: ['./business-search-criteria.component.css']
})
export class BusinessSearchCriteriaComponent extends SteppedComponent implements AfterViewInit {
  @Input() criteriaVariations: eCriteriaVariation[] = []
  @Input() isFormEnabled: boolean = true;
  protected jurisdictions: JurisdictionInfo[] = [];

  protected searchCriteriaDetails!: FormGroup;

  protected nameSearch = eBusinessSearchCriteriaType.Name;
  protected numberSearch = eBusinessSearchCriteriaType.Number;
  
  protected displayNameSearchFields: boolean = false;
  protected displayNumberSearchFields: boolean = false;

  nameError$ = new BehaviorSubject<string | null | undefined>(null);
  numberError$ = new BehaviorSubject<string | null | undefined>(null);
  jurisdictionsError$ = new BehaviorSubject<string | null | undefined>(null);

  private orderToValidate: Order | null = null;

  constructor(
    private formBuilder: FormBuilder,
    private environmentUrl: EnvironmentUrlService,
    private enumeratedTypesRepo: EnumeratedTypesRepositoryService,
    orderManager: OrderManagerService,
    validationService: ValidationService) {
    super(orderManager, validationService);
  }

  protected override init(order: Order): void {
    super.init(order);

    this.getOrderTypeJurisdictions();

    const criteria = this.order.businessSearchCriteria;
    if (criteria?.businessSearchCriteriaTypeID.toString() == "Name") {
      criteria!.businessSearchCriteriaTypeID = this.nameSearch;
    }

    if (criteria?.businessSearchCriteriaTypeID.toString() == "Number") {
      criteria!.businessSearchCriteriaTypeID = this.numberSearch;
    }

    this.searchCriteriaDetails = this.formBuilder.group({
      businessSearchCriteriaTypeID: [criteria?.businessSearchCriteriaTypeID],
      name: [criteria?.name],
      number: [criteria?.number],
      jurisdictions: [[]]
    })

    if (!this.isFormEnabled) {
      this.searchCriteriaDetails.disable();
    }

    this.searchCriteriaDetails.get('businessSearchCriteriaTypeID')!.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(val => {
        this.displayNameSearchFields = (val === this.nameSearch || val === "Name");
        this.displayNumberSearchFields = (val === this.numberSearch || val === "Number");
      });
    this.searchCriteriaDetails.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.validate(this.getOrderToValidate()));
  }

  ngAfterViewInit(): void {
    this.displayNameSearchFields = (this.searchCriteriaDetails.get('businessSearchCriteriaTypeID')!.value === this.nameSearch || this.searchCriteriaDetails.get('businessSearchCriteriaTypeID')!.value === "Name");
    this.displayNumberSearchFields = (this.searchCriteriaDetails.get('businessSearchCriteriaTypeID')!.value === this.numberSearch || this.searchCriteriaDetails.get('businessSearchCriteriaTypeID')!.value === "Number");
  }

  public override getValidationState(errors: ValidationProblem[] | undefined, order: Order): StepValidationState {
    return (errors?.length ?? 0) === 0 ? StepValidationState.ValidationSuccess : StepValidationState.ValidationError;
  }

  protected getOrderTypeJurisdictions() {
    this.enumeratedTypesRepo.getOrderTypeJurisdictions(this.environmentUrl.urlAddress)
      .subscribe(orderTypes => {
        var orderTypeJurisdictions: OrderTypeJurisdictions[] = [];
        orderTypeJurisdictions = orderTypes;

        const orderTypeJurisdiction = orderTypeJurisdictions.find(
          (orderTypeJurisdiction) => orderTypeJurisdiction.orderTypeID === eOrderType.businessSearch
        );
        
        if (orderTypeJurisdiction) {
          this.jurisdictions = orderTypeJurisdiction.jurisdictions;

          if (this.order.businessSearchCriteria?.jurisdictions?.length ?? 0 > 0 ) {
            this.searchCriteriaDetails.get('jurisdictions')?.setValue(
              this.order.businessSearchCriteria!.jurisdictions.map(j => j.jurisdiction)
            );
          } else {
            const allJurisdictions: string[] = this.jurisdictions
              .map(j => j.jurisdictionCode);
            this.searchCriteriaDetails.get('jurisdictions')?.setValue(allJurisdictions);
          }
        }
      })
  }

  isChecked(jurisdiction: string) {
    return this.getSelectedJurisdictions().includes(jurisdiction);
  }

  onCheckedChange(event: MatSlideToggleChange, jurisdiction: JurisdictionInfo): void {
    const selected = this.getSelectedJurisdictions();
  
    if (event.checked && !selected.includes(jurisdiction.jurisdictionCode)) {
      selected.push(jurisdiction.jurisdictionCode);
    } else if (!event.checked) {
      const index = selected.indexOf(jurisdiction.jurisdictionCode);
      if (index >= 0) {
        selected.splice(index, 1);
      }
    }
  
    this.searchCriteriaDetails.get('jurisdictions')?.setValue([...selected]);
  }

  public override onSaving(): void {
    const criteria = this.order.businessSearchCriteria;

    if(!criteria) {
      return;
    }

    this.updateBusinessSearchCriteria(criteria);
    this.order.businessSearchCriteria = criteria;
    this.orderManager.updateOrder(this.order);
  }

  override pushErrors(errors: ValidationProblem[] | undefined): void {
    if(!errors) {
      return;
    }

    this.nameError$.next(errors.filter(error => error.path.includes('/name'))?.at(0)?.userFriendlyMessage);
    this.numberError$.next(errors.filter(error => error.path.includes('/number'))?.at(0)?.userFriendlyMessage);
    this.jurisdictionsError$.next(errors.filter(error => error.path.includes('/jurisdictions'))?.at(0)?.userFriendlyMessage);
  }

  private getOrderToValidate(): Order {
    if(!this.orderToValidate) {
      this.orderToValidate = this.copyOrder(this.order);
    }

    const criteria = this.orderToValidate?.businessSearchCriteria;

    if(!criteria) {
      return this.orderToValidate!;
    }

    this.updateBusinessSearchCriteria(criteria);
    this.orderToValidate!.businessSearchCriteria = criteria;

    return this.orderToValidate!;
  }

  private updateBusinessSearchCriteria(criteria: BusinessSearchCriteria): void {
    criteria.businessSearchCriteriaTypeID = this.searchCriteriaDetails.get('businessSearchCriteriaTypeID')!.value;
    if (criteria.businessSearchCriteriaTypeID == this.nameSearch) {
      criteria.name = this.searchCriteriaDetails.get('name')!.value;
      criteria.number = null;
    } else {
      criteria.name = null;
      criteria.number = this.searchCriteriaDetails.get('number')!.value;;
    }

    const businessSearchJurisdictions: BusinessSearchJurisdiction[] =
      this.getSelectedJurisdictions().map((jurisdiction: string) => ({ id: "", businessSearchCriteriaID: criteria.id, jurisdiction: jurisdiction, added: new Date() }));
    criteria.jurisdictions = businessSearchJurisdictions;
  }

  getChipLabel(jurisdictionCode: string): string {
    return jurisdictionCode === 'CW' ? 'FED' : jurisdictionCode;
  }
  
  getSlideToggleLabel(jurisdictionFullName: string): string {
    return jurisdictionFullName === 'Country-Wide' ? 'Federal' : jurisdictionFullName;
  }

  selectAllToggles(): void {
    const allJurisdictions = this.jurisdictions.map((j) => j.jurisdictionCode);
    this.searchCriteriaDetails.get('jurisdictions')?.setValue(allJurisdictions);
  }
  
  deselectAllToggles(): void {
    this.searchCriteriaDetails.get('jurisdictions')?.setValue([]);
  }

  protected getSelectedJurisdictions(): string[] {
    return this.searchCriteriaDetails.get('jurisdictions')?.value ?? [];
  }
}
