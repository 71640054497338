<h3>Collateral Classifications</h3>

<form [formGroup]="ontarioCollateral">
    <!-- Checkbox list for various item categories -->
    <div class="flex-container flex-item padding-top" name="collateral-classifications">
        <mat-checkbox *ngIf="uiConfiguration.showConsumerGoods" class="flex-item" data-testid="consumer-goods-checkbox"
            [formControlName]="'consumerGoods'">
            Consumer Goods
        </mat-checkbox>
        <mat-checkbox *ngIf="uiConfiguration.showInventory" class="flex-item" data-testid="inventory-checkbox"
            [formControlName]="'inventory'">Inventory
        </mat-checkbox>
        <mat-checkbox *ngIf="uiConfiguration.showEquipment" class="flex-item" data-testid="equipment-checkbox"
            [formControlName]="'equipment'">Equipment
        </mat-checkbox>
    </div>

    <div class="flex-container flex-item padding-top">
        <div class="validated-form-field-container flex-container">
            <mat-checkbox *ngIf="uiConfiguration.showAccounts" class="flex-item" data-testid="accounts-checkbox"
                [formControlName]="'accounts'">Accounts</mat-checkbox>
            <mat-checkbox *ngIf="uiConfiguration.showOther" class="flex-item" data-testid="other-checkbox"
                [formControlName]="'other'">Other</mat-checkbox>
            <mat-checkbox *ngIf="uiConfiguration.showMVIncluded" class="flex-item" data-testid="mv-included-checkbox"
                [formControlName]="'mvIncluded'">
                Vehicle Included</mat-checkbox>
            <lib-error-bubble [control]="ontarioCollateral" [message]="collateralClassificationsError$"></lib-error-bubble>
        </div>
    </div>
</form>