import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil, forkJoin, of } from 'rxjs';
import { Order, OrderFee, OrderFeeRepositoryService, OrderManagerService } from 'reg-hub-common';
import { switchMap, map } from 'rxjs/operators';

@Component({
  selector: 'app-order-fees',
  templateUrl: './order-fees.component.html',
  styleUrls: ['./order-fees.component.css']
})
export class OrderFeesComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();
  isComplete: boolean = false;
  orderFees: OrderFee[] = [];
  totalCost: number = 0;
  emptyGuid = '00000000-0000-0000-0000-000000000000';
  displayedColumns: string[] = ['description', 'amount', 'tax'];

  constructor(private orderFeesRepo: OrderFeeRepositoryService,
    private orderManager: OrderManagerService
  ) { }

  ngOnInit(): void {
    this.orderManager.currentOrder
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(order => {
        this.getFees(order);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getFees(order: Order) {
    if (order != null && order.id != undefined) {
      this.isComplete = order.orderStatusTypeID == "Complete" || order.orderStatusTypeID == "Cancelled";
  
      // const fees$ = this.orderFeesRepo.getOrderFees(order.id);
      // const expectedFees$ = this.isComplete ? of([]) : this.orderFeesRepo.getExpectedOrderFees(order.id);
  
      // forkJoin({
      //   fees: fees$,
      //   expectedFees: expectedFees$
      // }).subscribe(({ fees, expectedFees }) => {
      //   this.orderFees = fees.concat(expectedFees);
      //   this.calculateTotal();
      // });

      this.orderFeesRepo.getCurrentAndExpectedOrderFees(order.id)
      .subscribe(fees => {
        this.orderFees = fees;
        this.calculateTotal();
      });
    }
  }

  calculateTotal(): void {
    this.totalCost = this.orderFees.reduce((acc, fee) => acc + fee.amount + fee.gst + fee.pst + fee.hst, 0);
  }

  getDescription(fee: OrderFee) {
    const feeType = fee.feeTypeID === "Disbursement" ? "Disb." : fee.feeTypeID;
    return fee.productCode.substring(0, 2) + ' ' + feeType;
  }
}
