import { Component, ViewChild } from '@angular/core';
import { Constants } from 'projects/reg-hub-client/src/constants';
import { Party, ReusableParty, OrderManagerService, ValidationService, ValidationProblem, Order, UIConfigurationService, ePartyType } from 'reg-hub-common';
import { ReusablePartyRepositoryService, PartyRepositoryService } from 'reg-hub-common';
import { HttpParams } from '@angular/common/http';
import { StepValidationState } from 'projects/reg-hub-client/src/interfaces/step';
import { PartiesComponent } from '../parties/parties.component';
import { PartiesListComponent } from 'reg-hub-common';

@Component({
  selector: 'app-secured-parties',
  templateUrl: './secured-parties.component.html',
  styleUrls: ['./secured-parties.component.css']
})
export class SecuredPartiesComponent extends PartiesComponent {
  @ViewChild(PartiesListComponent) partiesListComponent!: PartiesListComponent;

  reusableSecuredParties: ReusableParty[] = [];

  protected businessSecuredParty = ePartyType.businessSecuredParty;
  protected individualSecuredParty = ePartyType.individualSecuredParty;

  constructor(
    private reusablePartyRepo: ReusablePartyRepositoryService,
    partyRepo: PartyRepositoryService,
    uiConfigurationService: UIConfigurationService,
    orderManager: OrderManagerService,
    validationService: ValidationService) {
    super(partyRepo, uiConfigurationService, orderManager, validationService);
  }

  protected override init(order: Order): void {
    super.init(order);

    this.reusablePartyRepo.populateBaseLink([this.order.orderGroup!.corporationID]);
    var reusablePartyParams = new HttpParams().set('isVisible', true);
    reusablePartyParams = reusablePartyParams.append('PartyTypeIDs', 'BusinessSecuredParty');
    reusablePartyParams = reusablePartyParams.append('PartyTypeIDs', 'IndividualSecuredParty');

    this.reusablePartyRepo.getFirstPageSubscribable(reusablePartyParams).subscribe(parties => {
      this.reusableSecuredParties = parties;
    });
  }

  public override getValidationState(errors: ValidationProblem[] | undefined, order: Order): StepValidationState {
    return PartiesComponent.parseErrorsForValidationState(errors, order, Constants.isSecuredParty);
  }

  public override getParties(): Party[] {
    const filteredSecuredParties = this.order.parties?.filter((party) => Constants.isSecuredParty(party.partyTypeID));
    return filteredSecuredParties ?? [];
  }

  override onSaving(): void {
    var partyToReplace = this.partiesListComponent.onSaving();
    if (partyToReplace) {
      this.replaceParty(partyToReplace!);
    }
  }
}
