<div class="padding" *ngIf="_reportTemplate">
    <mat-card>
        <mat-card-content>
            <div class="content">
                <h1>{{ _reportTemplate.name }}</h1>
                <span class="spacer"></span>
                <button mat-fab matTooltip="Save Report" matTooltipClass="reghub-tooltip" (click)="onSaveReport()"
                    aria-label="Button that saves the current report data as csv." color="accent">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
            <h3>{{ _reportTemplate.description }}</h3>

            <div class="table-container">
                <mat-tab-group animationDuration="0ms" [selectedIndex]="_selectedIndex">
                    <mat-tab label="Live Report">
                        <app-live-template-report></app-live-template-report>
                    </mat-tab>
                    <mat-tab label="Saved Reports">
                        <app-saved-template-reports-list></app-saved-template-reports-list>
                    </mat-tab>
                    <!-- <mat-tab label="Schedule Reports">
                        Coming Soon!
                    </mat-tab> -->
                </mat-tab-group>
            </div>
        </mat-card-content>
    </mat-card>
</div>